@media only screen and (min-width: 768px) {
  .c-scrollbar {
    z-index: 50;
  }

  .c-scrollbar_thumb {
    background-color: #14CF93;
    opacity: 1;
  }

  body {
    background-color: #FCFAF8;
  }

  h1, h2, h3, h4, h5 {
    line-height: 95%;
  }

  a {
    color: #414141;
  }

  .o-desktop {
    display: inherit !important;
  }

  .o-mobile {
    display: none !important;
  }

  .o-section {
    margin: 0 5vw;
  }

  .o-title {
    color: #383838;
    font-size: 90px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .o-title {
    font-size: calc(3.90625vw + 60px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .o-title {
    font-size: calc(17.4418604651vw - 113.2558139535px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .o-title {
    font-size: calc(13.5135135135vw - 59.5945945946px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1440px) {
  .o-title {
    font-size: calc(5.2083333333vw + 60px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .o-title {
    font-size: calc(6.8181818182vw + 29.0909090909px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 2800px) {
  .o-title {
    font-size: 220px;
  }
}
@media only screen and (min-width: 768px) {
  @keyframes roundb-1 {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    41% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
@media only screen and (min-width: 768px) {
  @keyframes roundb-2 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    41% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
@media only screen and (min-width: 768px) {
  @keyframes roundb-3 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    41% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    61% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
@media only screen and (min-width: 768px) {
  @keyframes roundb-4 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    41% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    81% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
@media only screen and (min-width: 768px) {
  @keyframes roundb-5 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    41% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
@media only screen and (min-width: 768px) {
  .c-cursor {
    position: fixed;
    z-index: 5;
    display: block;
    width: 25vw;
    pointer-events: none;
  }
  .c-cursor > div {
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0, 0.89, 0.41, 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .c-cursor > div p {
    padding: 12px 35px;
    background: #fff;
    color: #414141;
    font-size: 12px;
    display: inline-block;
    border-radius: 150px;
    margin-bottom: 15px;
    position: relative;
    transform: translate(35%, 0);
  }
  .c-cursor > div p::after {
    position: absolute;
    height: 94%;
    padding-left: 26%;
    border: 2px solid #fff;
    content: "";
    top: 0;
    left: -34%;
    border-radius: 50px;
    z-index: 2;
  }
  .c-cursor > div div {
    position: relative;
  }
  .c-cursor > div div img {
    position: absolute;
    width: 100%;
    opacity: 0;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .c-cursor > div.--visible {
    opacity: 1;
  }
  .c-cursor > div.--visible div img {
    animation-duration: 4s;
  }
  .c-cursor > div.--visible div img:nth-child(1) {
    animation-name: roundb-1;
  }
  .c-cursor > div.--visible div img:nth-child(2) {
    animation-name: roundb-2;
  }
  .c-cursor > div.--visible div img:nth-child(3) {
    animation-name: roundb-3;
  }
  .c-cursor > div.--visible div img:nth-child(4) {
    animation-name: roundb-4;
  }
  .c-cursor > div.--visible div img:nth-child(5) {
    animation-name: roundb-5;
  }
}
@media only screen and (min-width: 768px) {
  nav {
    top: 6.5vh;
    padding: 0 5vw;
    color: #383838;
    pointer-events: none;
  }
  nav a {
    color: inherit;
    position: relative;
    text-decoration: none;
    pointer-events: all;
  }
  nav a::after, nav a::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #4E4E4E;
    height: 1px;
    transition: 0.8s;
    transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
  }
  nav a::after {
    right: 0;
    transform-origin: right;
  }
  nav a::before {
    left: 0;
    transform-origin: left;
    transform: scale(0, 1);
    transition-delay: 0.2s;
  }
  nav a:hover::after {
    transform: scale(0, 1);
    transition: 0;
  }
  nav a:hover::before {
    transform: scale(1, 1);
    transition-delay: 0;
  }
  nav.--white {
    color: #F2F2F2;
  }
  nav.--white a::after, nav.--white a::before {
    background-color: #F2F2F2;
  }
}
@media only screen and (min-width: 768px) {
  .c-nav-social {
    position: relative;
    display: inline-block;
  }
  .c-nav-social > a {
    z-index: 4;
    position: relative;
  }
  .c-nav-social:hover {
    color: #383838;
  }
}
@media only screen and (min-width: 768px) {
  .c-nav-social__dropdown {
    position: absolute;
    top: -20px;
    left: -74%;
    width: 12vw;
    background: #fff;
    border-radius: 25px;
    visibility: hidden;
    transition: 0.4s;
    transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
    opacity: 0;
    transform: translate(0, -10px);
    transform-origin: top;
  }
  .c-nav-social__dropdown::after {
    content: url(drop-down.0028b9b3.svg);
    position: absolute;
    right: 28px;
    top: 15px;
    z-index: 4;
    width: 20px;
  }
  .c-nav-social__dropdown ul {
    padding-top: 58px;
    padding-bottom: 8px;
    position: relative;
  }
  .c-nav-social__dropdown ul::before {
    content: "";
    width: 80%;
    height: 1px;
    position: absolute;
    top: 54px;
    display: block;
    background-color: rgba(65, 65, 65, 0.3);
    right: 10%;
    transition: 0.4s;
    transform: scale(0);
  }
  .c-nav-social__dropdown li {
    padding: 10px 25px;
    margin: 5px 5px;
    position: relative;
    cursor: pointer;
    opacity: 0;
    transform: translate(0, 20%);
    transition: 0.8s;
    transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
    border-radius: 50px;
  }
  .c-nav-social__dropdown li a {
    position: relative;
    z-index: 1;
    color: #383838;
    border-radius: 50px;
    text-decoration: none !important;
  }
  .c-nav-social__dropdown li a::after, .c-nav-social__dropdown li a::before {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .c-nav-social:hover .c-nav-social__dropdown {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }
  .c-nav-social:hover .c-nav-social__dropdown ul::before {
    transform: scale(1);
  }
  .c-nav-social:hover .c-nav-social__dropdown li {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: calc(var(--order) * 100ms);
  }
}
@media only screen and (min-width: 768px) {
  .c-title {
    padding: 24vh 0 0 0;
  }
}
@media only screen and (min-width: 768px) {
  .c-title__svg {
    width: 42%;
    transform: translate(0, -26%);
    align-items: flex-end;
    justify-content: space-between;
  }
  .c-title__svg .c-down-btn {
    width: 4.2vw;
    transform: translate(2px, -5px);
  }
  .c-title__svg svg {
    width: 210px;
    margin: 0px 0;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-title__svg svg {
    width: calc(22.4609375vw + 37.5px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-title__svg svg {
    width: calc(17.4418604651vw + 101.7441860465px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .c-title__svg svg {
    width: calc(21.6606498195vw + 44.1155234657px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-title__svg svg {
    width: calc(10.7954545455vw + 252.7272727273px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 2800px) {
  .c-title__svg svg {
    width: 555px;
  }
}
@media only screen and (min-width: 768px) {
  ._title-fit {
    line-height: 115%;
    margin-top: -3%;
  }
}
@media only screen and (min-width: 768px) {
  .c-title__row {
    display: flex;
  }
  .c-title__row h2 {
    overflow-y: hidden;
    color: #383838;
    font-size: 108px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-title__row h2 {
    font-size: calc(12.109375vw + 15px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-title__row h2 {
    font-size: calc(13.9534883721vw - 8.6046511628px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .c-title__row h2 {
    font-size: calc(10.8108108108vw + 34.3243243243px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1440px) {
  .c-title__row h2 {
    font-size: calc(12.5vw + 10px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-title__row h2 {
    font-size: calc(3.4090909091vw + 184.5454545455px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 2800px) {
  .c-title__row h2 {
    font-size: 280px;
  }
}
@media only screen and (min-width: 768px) {
  .c-title__row:nth-child(2) {
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 768px) {
  .c-t-a-info {
    padding-top: 3.18%;
    white-space: nowrap;
    padding-left: 52px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-t-a-info {
    padding-left: calc(11.328125vw - 35px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-t-a-info {
    padding-left: calc(5.8139534884vw + 35.5813953488px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .c-t-a-info {
    padding-left: calc(11.3718411552vw - 40.3393501805px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-t-a-info {
    padding-left: 178px;
  }
}
@media only screen and (min-width: 768px) {
  .c-t-a-info:nth-child(1) {
    margin-right: 9vw;
  }
}
@media only screen and (min-width: 768px) {
  .c-t-a-info--desktop {
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .c-t-a-info--mobile {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .c-about {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    margin-bottom: 8vh;
    margin-top: 9vh;
  }
  .c-about > div:first-child {
    width: 30%;
  }
  .c-about p {
    margin-top: 0vh;
    width: 96%;
    color: #414141;
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-about p {
    font-size: calc(1.171875vw + 11px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-about p {
    font-size: calc(9.3023255814vw - 93.0697674419px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .c-about p {
    font-size: calc(5.4054054054vw - 39.8378378378px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1440px) {
  .c-about p {
    font-size: calc(0.8333333333vw + 26px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-about p {
    font-size: calc(1.4772727273vw + 13.6363636364px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 2800px) {
  .c-about p {
    font-size: 55px;
  }
}
@media only screen and (min-width: 768px) {
  .c-about-cards {
    width: 42%;
    transform: translate(9%, 30%);
  }
  .c-about-cards > div {
    width: 65%;
    overflow: hidden;
    transform-origin: bottom;
  }
  .c-about-cards > div img {
    width: 105%;
  }
  .c-about-cards > div:nth-child(1) {
    top: -12%;
    left: -16%;
    position: absolute;
    transform: rotate(-5.62deg);
  }
  .c-about-cards > div:nth-child(2) {
    position: relative;
    z-index: 2;
    transform: translate(0, 0);
  }
  .c-about-cards > div:nth-child(3) {
    top: 12%;
    left: 16%;
    position: absolute;
    transform: rotate(3.91deg);
    z-index: 3;
  }
}
@media only screen and (min-width: 768px) {
  .c-works {
    padding: 32vh 5vw 10vh 5vw;
    position: relative;
    background-color: #F2F2F2;
  }
  .c-works::before, .c-works::after {
    content: "";
    background: url("rip.f6d7c4dd.svg");
    position: absolute;
    width: 100%;
    left: 0;
    height: 150px;
    background-size: cover;
  }
  .c-works::before {
    top: 0;
    transform: rotate(-180deg) translate(0, 55%);
  }
  .c-works::after {
    bottom: 0;
    transform: rotateY(-180deg) translate(0, 55%);
  }
  .c-works .c-work-bg::before {
    background-color: #F2F2F2;
  }
  .c-works hr {
    width: 86%;
    background-color: rgba(65, 65, 65, 0.3);
    margin: 18vh 0 24vh 14%;
  }
}
@media only screen and (min-width: 768px) {
  .c-services {
    display: flex;
  }
  .c-services svg {
    width: auto;
    stroke: rgba(65, 65, 65, 0.3);
  }
  .c-services > div {
    margin: 2vw 0 0;
    margin-left: 35px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-services > div {
    margin-left: calc(12.109375vw - 58px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-services > div {
    margin-left: calc(13.9534883721vw - 81.6046511628px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .c-services > div {
    margin-left: calc(13.5135135135vw - 75.5945945946px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1440px) {
  .c-services > div {
    margin-left: calc(12.2916666667vw - 58px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-services > div {
    margin-left: calc(4.7872340426vw + 86.085106383px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 2860) {
  .c-services > div {
    margin-left: 223px;
  }
}
@media only screen and (min-width: 768px) {
  .c-services p {
    width: 40%;
    font-size: 20px;
    color: #414141;
  }
}
@media only screen and (min-width: 768px) {
  .c-works_title {
    padding-left: 13.4%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22vh;
    align-items: baseline;
  }
  .c-works_title div {
    display: flex;
    align-items: flex-end;
  }
  .c-works_title div p {
    margin-left: 30px;
    transform: translate(0, -18px);
    font-size: 20px;
    color: #414141;
  }
  .c-works_title h2 {
    display: flex;
    justify-content: flex-end;
    color: #383838;
  }
  .c-works_title h2 > span:last-child {
    margin-left: 20px;
    text-align: right;
    margin-top: 0;
  }
  .c-works_title svg {
    transform: rotate(90deg);
    stroke: rgba(65, 65, 65, 0.3);
  }
}
@media only screen and (min-width: 768px) {
  .c-project__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16vh;
  }
  .c-project__row .u-width-1 {
    width: 55%;
  }
  .c-project__row .u-width-2 {
    width: 32%;
    transform: translate(-20%, 0);
  }
  .c-project__row .u-width-2 a {
    padding-top: 68% !important;
  }
  .c-project__row .u-width-3 {
    width: 55%;
    transform: translate(35%, 0);
  }
  .c-project__row .u-width-3 a {
    padding-top: 66% !important;
  }
  .c-project__row .u-width-4 {
    width: 34%;
    transform: translate(0, 42%);
  }
  .c-project__row .u-width-4 a {
    padding-top: 60% !important;
  }
  .c-project__row .u-width-5 {
    width: 58%;
  }
  .c-project__row .u-width-5 a {
    padding-top: 60% !important;
  }
}
@media only screen and (min-width: 768px) {
  .c-project {
    border-radius: 50px;
    margin: 0;
  }
  .c-project a {
    padding-top: 75%;
  }
  .c-project a img {
    width: 105%;
    left: -2.5%;
    top: -3%;
    transition: 0.6s cubic-bezier(0, 0.89, 0.41, 1);
  }
  .c-project:hover img {
    filter: saturate(0);
  }
}
@media only screen and (min-width: 768px) {
  .c-project-cta p {
    font-size: 36px;
    text-transform: none;
    background: #fff;
    padding: 15px 55px;
  }
}
@media only screen and (min-width: 768px) {
  .c-prj-btn {
    padding: 24px;
  }
  .c-prj-btn svg {
    width: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .c-archive {
    margin-left: 65.4%;
    margin-top: 50vh;
  }
  .c-archive a {
    color: #383838;
  }
}
@media only screen and (min-width: 768px) {
  .c-play-btn {
    padding: 24px 0 !important;
    margin-top: 25vh !important;
    font-size: 20px !important;
    position: relative;
    overflow: hidden;
    color: #414141;
  }
  .c-play-btn > span {
    position: relative;
    z-index: 2;
  }
  .c-play-btn:hover > span {
    color: #fff;
  }
}
@media only screen and (min-width: 768px) {
  .c-honors {
    padding: 20vh 0 18vh 0;
  }
  .c-honors .o-ui-arrow {
    stroke: rgba(65, 65, 65, 0.3);
  }
}
@media only screen and (min-width: 768px) {
  .c-honors__title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20vh;
  }
  .c-honors__title > span {
    margin-bottom: 0;
    margin-left: 176px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-honors__title > span {
    margin-left: calc(19.53125vw + 26px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-honors__title > span {
    margin-left: calc(-1.875vw + 300px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1440px) {
  .c-honors__title > span {
    margin-left: calc(31.0416666667vw - 174px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-honors__title > span {
    margin-left: 422px;
  }
}
@media only screen and (min-width: 768px) {
  .c-honors__wrap {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
  }
  .c-honors__wrap > div:first-child {
    width: 20%;
    text-align: left;
  }
  .c-honors__wrap > div:first-child > svg {
    transform: rotate(0deg) translate(0, -216%);
    margin-bottom: 12vh;
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  .c-featured {
    padding: 0;
  }
  .c-featured p {
    width: 100%;
    color: #414141;
    font-family: gilroy-semi-bold;
  }
}
@media only screen and (min-width: 768px) {
  .c-honors__list {
    width: 64% !important;
    padding-bottom: 0;
  }
  .c-honors__list li {
    padding: 30px 0;
    border-bottom: 1px solid rgba(65, 65, 65, 0.3);
  }
  .c-honors__list li .o-title-small {
    width: 15.5%;
    margin-bottom: 0 !important;
  }
  .c-honors__list li a {
    background: #414141;
  }
  .c-honors__list li a img {
    filter: invert(1);
  }
  .c-honors__list li div {
    display: flex;
    width: 80%;
    align-items: center;
  }
  .c-honors__list li div p {
    padding-left: 8vw;
    font-size: 30px;
    color: #414141;
    font-family: gilroy-semi-bold;
  }
}
@media only screen and (min-width: 768px) {
  footer {
    width: 100%;
    margin-top: 5vh;
    background-color: #253B39;
    padding: 20vh 5vw 0 5vw;
  }
  footer #c-circle {
    position: absolute;
    bottom: -68%;
    width: 82%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@media only screen and (min-width: 768px) {
  .c-footer {
    margin-bottom: 10vh;
  }
  .c-footer > div > h2 {
    color: #383838;
  }
}
@media only screen and (min-width: 768px) {
  .c-footer__title {
    position: relative;
    text-transform: uppercase;
    width: 100%;
  }
  .c-footer__title h2 {
    line-height: 86%;
  }
  .c-footer__title h2 .u-inline {
    display: inline-block !important;
  }
  .c-footer__title > div:first-child {
    position: absolute;
    bottom: 5px;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) {
  .c-footer__cta {
    margin-top: 15vh;
    margin-bottom: 8vh;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .c-footer__cta p, .c-footer__cta a {
    color: #fff;
  }
  .c-footer__cta > div {
    width: 50%;
    margin: 0;
  }
  .c-footer__cta > div:first-of-type {
    padding-right: 2%;
  }
  .c-footer__cta > div:last-of-type {
    padding-left: 2%;
  }
  .c-footer__cta > div a {
    font-size: 24px;
    padding: 42px 0;
    height: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .c-footer__socials > div:first-child {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .c-footer__socials > div:first-child > div {
    width: 21.2%;
  }
  .c-footer__socials > div:first-child > div a {
    padding: 14px 0;
  }
  .c-footer__socials > div:first-child > div a p {
    color: #fff;
  }
  .c-footer__socials > div:first-child > div a:hover {
    padding: 14px 20px;
  }
  .c-footer__socials a img {
    width: 42px;
  }
}
@media only screen and (min-width: 768px) {
  .c-footer__copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    font-size: 14px;
  }
  .c-footer__copy p > span:nth-child(1) {
    opacity: 0.6;
  }
  .c-footer__copy div {
    margin-top: 0;
  }
  .c-footer__copy div a:last-child {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .c-credits {
    display: flex;
    justify-content: space-between;
    padding: 5vh 5vw;
  }
  .c-credits div {
    margin: 0;
  }
  .c-credits a {
    margin: 0 15px 0 0;
    display: inline-block;
  }
}
@media only screen and (min-width: 768px) {
  .c-loader h2 {
    font-size: 80px;
    line-height: 130%;
  }
}
@media only screen and (min-width: 768px) {
  .c-play-title h2 {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .c-play-title h2 {
    margin-right: calc(8.7890625vw - 32.5px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .c-play-title h2 {
    margin-right: calc(9.3023255814vw - 39.0697674419px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .c-play-title h2 {
    margin-right: calc(6.4981949458vw - 0.7653429603px);
  }
}
@media only screen and (min-width: 768px) and (min-width: 1920px) {
  .c-play-title h2 {
    margin-right: 124px;
  }
}
@media only screen and (min-width: 768px) {
  .c-play-title a img {
    filter: invert(0);
  }
}
@media only screen and (min-width: 768px) {
  .c-play__bg {
    background-color: #F2F2F2;
  }
}
@media only screen and (min-width: 768px) {
  .c-home__bg {
    background-color: #FCFAF8;
  }
}
@media only screen and (min-width: 768px) {
  .o-play-row {
    display: flex;
    justify-content: space-between;
    margin: 30vh 0;
  }
  .o-play-row img {
    width: 100%;
    margin: 0;
  }
  .o-play-row.u-first-row {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20vh;
    margin-bottom: 70vh;
  }
  .o-play-row.u-first-row > div:first-child {
    width: 52%;
    height: auto;
  }
  .o-play-row.u-first-row > div:last-child {
    width: 30%;
    margin-right: 8%;
  }
  .o-play-row.u-first-row > div:last-child p {
    margin-bottom: 25vh;
  }
}
@media only screen and (min-width: 768px) {
  .u-type-1 {
    margin: 10vh 0 40vh 0;
  }
  .u-type-1 div {
    width: 28%;
  }
  .u-type-1 div:nth-child(2) {
    transform: translate(0, 50%);
  }
  .u-type-1 .u-type-1_s {
    transform: translate(0, -50%);
  }
}
@media only screen and (min-width: 768px) {
  .u-type-2 {
    margin: 50vh 0 30vh 0;
    justify-content: center;
  }
  .u-type-2 img {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .u-type-3 {
    margin: 60vh 0 40vh 0;
    justify-content: space-between;
  }
  .u-type-3 img:last-child {
    width: 24%;
    height: 100%;
    transform: translate(-20%, -50%);
  }
  .u-type-3 img:first-child {
    width: 55%;
  }
}
@media only screen and (min-width: 768px) {
  .button--bestia .button__bg::before {
    padding-bottom: 45%;
  }
}
@media only screen and (min-width: 768px) {
  #awwwards {
    display: block;
  }
}
@media only screen and (min-width: 2800px) {
  nav a, .c-title p {
    font-size: 18px;
  }

  .o-section {
    margin: 0 14vw;
  }

  .c-works {
    padding: 32vh 14vw 10vh 14vw;
  }
  .c-works hr {
    margin: 12vh 0 11vh 14%;
  }

  .c-about {
    margin-bottom: 12vh;
    margin-top: 12vh;
  }
}
/*# sourceMappingURL=index.2b8c6830.css.map */
